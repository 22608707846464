export const colors = {
  pastel: ["#a8e6cf", "#fdffab", "#ffd3b6", "#ffaaa5"],
  general: {
    blue: "#00ADEF",
    green: "#45D88E",
    red: "#ED2228",
    orange: "#FF9200",
    yellow: "#FFCB05",
  },
};

///// Broadcast /////
export const getGenderList = (value) => {
  switch (value) {
    case "male":
      return "ชาย";
    case "female":
      return "หญิง";
    case "":
      return "ไม่ระบุ";
    default:
      return value;
  }
};

export const getCareerList = (value) => {
  switch (value) {
    case "รับราชการ/รัฐวิสาหกิจ":
      return "รับราชการ / รัฐวิสาหกิจ";
    case "รับจ้างทั่วไป":
      return "รับจ้างทั่วไป";
    case "นักเรียน นิสิต นักศึกษา":
      return "นักเรียน / นิสิต / นักศึกษา";
    case "พนักงานเอกชน":
      return "พนักงานเอกชน";
    case "อาชีพอิสระ / ค้าขาย":
      return "อาชีพอิสระ / ค้าขาย";
    case "อื่นๆ":
      return "อื่นๆ";
    case "":
      return "ไม่ระบุ";
    default:
      return value;
  }
};

export const getOSList = (value) => {
  switch (value) {
    case "ios":
      return "iOS";
    case "android":
      return "Android";
    case "sansiri":
      return "Sansiri";
    case "linux":
      return "Linux";
    case "":
      return "ไม่ระบุ";
    default:
      return value;
  }
};

export const baseURL = process.env.REACT_APP_GO_BASE_URL;
export const baseURL2 = process.env.REACT_APP_PHP_BASE_URL;

export const tw_Middleware = process.env.REACT_APP_MIDDLEWARE_API_URL;
export const tw_promotion = process.env.REACT_APP_PROMOTION_API_URL;
export const tw_mobileapp = process.env.REACT_APP_MOBILE_APP_API_URL;
// export const tw_promotion = "http://localhost:9000";

import axios from '../../config/axios';
import axios_mobileapp from '../../config/axios-mobileapp';
import axios_twpromotion from '../../config/axios-twpromotion';
const apiPMTGetTopupMobileapp = async () => {
  try {
    const result = await axios_mobileapp.get('/api/credit-promotion')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTEditTopupMobileapp = async (data,id) => {
  try {
    const result = await axios_mobileapp.put(`/api/credit-promotion/${id}`, data)
    console.log(`/api/credit-promotion/${id}`, data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTGetTopup = async () => {
  try {
    const result = await axios.get('/promotion/topup')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTEditTopup = async (data) => {
  try {
    const result = await axios.put('/promotion/topup', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTGetTimeCount = async () => {
  try {
    const result = await axios.get('/promotion/timeCount')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTEditTimeCount = async (data) => {
  try {
    const result = await axios.put('/promotion/timeCount', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTGetReward = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/promotion/reward/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTAddReward = async (data) => {
  try {
    const result = await axios.post('/promotion/reward', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTGetRewardById = async (reward_id) => {
  try {
    const result = await axios.get(`/promotion/reward?reward_id=${reward_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTEditRewardById = async (data) => {
  try {
    const result = await axios.put('/promotion/reward', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTDeleteRewardById = async (reward_id) => {
  try {
    const result = await axios.delete(`/promotion/reward?reward_id=${reward_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTGetPoint = async () => {
  try {
    const result = await axios.get('/promotion/point')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTEditPoint = async (data) => {
  try {
    const result = await axios.put('/promotion/point', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTGetFriend = async () => {
  try {
    const result = await axios.get('/promotion/friend')
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTEditFriend = async (data) => {
  try {
    const result = await axios.put('/promotion/friend', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTGetPromoCode = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/promotion/promoCode/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTAddPromoCode = async (data) => {
  try {
    const result = await axios.post('/promotion/promoCode', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTGetPromoCodeById = async (promo_code_id) => {
  try {
    const result = await axios.get(`/promotion/promoCode?promo_code_id=${promo_code_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTEditPromoCodeById = async (data) => {
  try {
    const result = await axios.put('/promotion/promoCode', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTDeletePromoCodeById = async (promo_code_id) => {
  try {
    const result = await axios.delete(`/promotion/promoCode?promo_code_id=${promo_code_id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTGetRedeem = async (get_all, cur_page, per_page, keyword) => {
  try {
    const result = await axios.get(`/promotion/redeem/list?get_all=${get_all}&cur_page=${cur_page}&per_page=${per_page}&keyword=${keyword}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}

const apiPMTApproveRejectRedeem = async (data) => {
  try {
    const result = await axios.put('/promotion/redeem', data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}
const apiPMTAddPromotionMobileapp = async (data,id) => {
  try {
    const result = await axios_twpromotion.post(`/Promotion/add-promotion`, data)
    //console.log(`/api/credit-promotion/${id}`, data)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}
const apiSGetSitePromotionMobileapp = async ( cur_page, per_page) => {
  try {
    const result = await axios_twpromotion.get(`/Promotion/promotions?per_page=${per_page}&cur_page=${cur_page}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}
const apiSDeleteSitePromotionMobileapp = async (id) => {
  try {
    const result = await axios_twpromotion.delete(`/Promotion/promotions/${id}`)
    return result && result.data
  } catch (error) {
    return error && error.response && error.response.data
  }
}
export {
  apiPMTGetTopup,
  apiPMTEditTopup,
  apiPMTGetTimeCount,
  apiPMTEditTimeCount,
  apiPMTGetReward,
  apiPMTAddReward,
  apiPMTGetRewardById,
  apiPMTEditRewardById,
  apiPMTDeleteRewardById,
  apiPMTGetPoint,
  apiPMTEditPoint,
  apiPMTGetFriend,
  apiPMTEditFriend,
  apiPMTGetPromoCode,
  apiPMTAddPromoCode,
  apiPMTGetPromoCodeById,
  apiPMTEditPromoCodeById,
  apiPMTDeletePromoCodeById,
  apiPMTGetRedeem,
  apiPMTApproveRejectRedeem,
  apiPMTGetTopupMobileapp,
  apiPMTEditTopupMobileapp,
  apiPMTAddPromotionMobileapp,
  apiSGetSitePromotionMobileapp,
  apiSDeleteSitePromotionMobileapp
}